<template>
  <div class="banner">
    <div class="title">
      <slot name="title" />
    </div>
    <div class="info">
      <slot name="info" />
    </div>
    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="less">
.banner {
  width: 100%;
  height: 5.3rem;
  padding: 0 .16rem;
  margin-top: .92rem;

  .title {
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Plus Jakarta Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 33.8px */
    // background: linear-gradient(183deg,
    //     #6446b5 3.26%,
    //     rgba(103, 70, 189, 0) 177.65%);

    background: linear-gradient(176.01deg, #6446B5 3.26%, rgba(103, 70, 189, 0) 177.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: .24rem;
  }

  .info {
    color: #5C5A60;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Plus Jakarta Sans;
    font-size: .14rem;
    font-style: normal;
    font-weight: 500;
    margin-top: .16rem;
    margin-bottom: .32rem;
		line-height: 130%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>